import { Coin } from '../shared/types';
import './CoinSelectorItem.scss';

interface Props {
  coin: Coin;
  selectedCoin: Coin;
  onCoinSelected: (coin:Coin) => void
};

export default function CoinSelectorItem(props: Props): JSX.Element {

  return (
    <div className={
      props.selectedCoin.id === props.coin.id ? 
      'coin-selector-item-container-disabled coin-selector-item-container d-flex align-items-center':
      'coin-selector-item-container d-flex align-items-center'
      }
      onClick={() => {if(props.selectedCoin.id !== props.coin.id)props.onCoinSelected(props.coin)}}>
      <img className="coin-icon" alt="coin" src={props.coin.logo}/>
      <div className='coin-info-wrapper'>
        <div className='coin-name'>{props.coin.name}</div>
        <div className='coin-symbol'>{props.coin.symbol}</div>
      </div>
    </div>
  );
}