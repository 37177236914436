import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import './TextField.scss';
import { FaInfoCircle } from 'react-icons/fa';
const ReactHtmlParser = require('html-react-parser');



interface Props {
    name?: string;
    placeholderText?: string;
    selectedOutlineColor?: string;
    hoverOutlineColor?: string;
    marginBottom?:string;
    value?: any;
    prefix?: any;
    numbersOnly?: boolean;
    numberFormatter?: boolean;
    decimalScale?: number;
    valueChanged?: (value:any) => void;
    disabled?: boolean;
    infoText?: string;
};


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    borderRadius: "16px",
    color: 'rgba(0, 0, 0, 0.87)',
    padding: "15px",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function TextField(props: Props): JSX.Element {
  const [hover, setHover] = useState(false);
  const [selected, setSelected] = useState(false);

    // mount
    useEffect(() => {

    }, []);
  
    // unmount
    useEffect(() => {
      return () => {
        
      }
    }, []);

  return (
   <div className='calc-input-container' style={{marginBottom: props.marginBottom ? props.marginBottom : ''}}>
      {
        props.name &&
        <div className='input-name-wrapper mb-2'>
          <label className='input-name'>{props.name}</label>
          {
            props.infoText &&
            <HtmlTooltip
              enterTouchDelay={0}
              leaveTouchDelay={20000}
              title={
                <React.Fragment>
                  {ReactHtmlParser(props.infoText)}
                </React.Fragment>
              }
            >
              <span className='info-icon-wrapper'><FaInfoCircle className='info-icon'/></span>
           </HtmlTooltip>
          }
        </div>
      }
       <div 
        className='input-wrapper d-flex align-items-center'
        style={
                props.selectedOutlineColor && selected ?
                {border: props.selectedOutlineColor + " solid 1px"} :
                props.hoverOutlineColor && hover ?
                {border: props.hoverOutlineColor + " solid 1px"} : {}
              }
        onMouseEnter={()=>{setHover(true && props.disabled !== true)}} onMouseLeave={()=>{setHover(false)}}
      >
            {
              props.numberFormatter ?
              <NumberFormat
                style={{width: "100%", backgroundColor: "#F2F2F2", cursor: props.disabled ? "not-allowed" : "default"}}
                thousandsGroupStyle="thousand"
                value={props.value}
                onValueChange={(values:any,sourceInfo:any) => { 
                  // console.log(values,sourceInfo);
                  if (sourceInfo.source !== 'prop' && props.valueChanged) {
                    props.valueChanged(values.floatValue);
                  }
                }}
                prefix={props.prefix}
                decimalScale={props.decimalScale}
                displayType="input"
                type="text"
                thousandSeparator={true}
                allowNegative={false}
                disabled={props.disabled}
               /> :
                <input
                style={{cursor: props.disabled ? "not-allowed" : "default"}}
                  className='input'
                  type={props.numbersOnly ? "number" : ""}
                  placeholder={props.placeholderText ? props.placeholderText : ''}
                  onClick={()=>{setSelected(true)}}
                  onBlur={()=>{setSelected(false)}}
                  onChange={(e) => {
                    if (props.valueChanged)
                      props.valueChanged(e.target.value)}
                  }
                  disabled={props.disabled}
                />
            }
       </div>
    </div>
  );
}