import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import './AdvancedCalcInput.scss';



interface Props {
    name: string;
    multiplier?:number;
    placeholderText?: string;
    selectedOutlineColor?: string;
    hoverOutlineColor?: string;
    marginBottom?:string;
    value?: any;
    prefix?: any;
    numbersOnly?: boolean;
    decimalScale?: number;
    valueChanged?: (value:any) => void;
    disabled?: boolean;
    infoText?: string;
};


export default function AdvancedCalcInput(props: Props): JSX.Element {
  const [hover, setHover] = useState(false);
  const [selected, setSelected] = useState(false);

    // mount
    useEffect(() => {

    }, []);
  
    // unmount
    useEffect(() => {
      return () => {
        
      }
    }, []);

  return (
   <div className='advanced-input-container' style={{marginBottom: props.marginBottom ? props.marginBottom : ''}}>
        <div className='d-flex justify-content-between'>
          <div className='input-name-wrapper'>
            <label className='input-name'>{props.name}</label>
          </div>
          {/* {
          props.multiplier && <label className={props.multiplier > 0 ?'multiplier-increase' : 'multiplier-decrease'}>
            &nbsp;{props.multiplier != 1 && <>{props.multiplier}x</>}
          </label>
          } */}
        </div>
       <div 
        className='input-wrapper d-flex flex-column justify-content-center'
        style={
          props.selectedOutlineColor && selected ?
          {border: props.selectedOutlineColor + " solid 1px"} :
          props.hoverOutlineColor && hover ?
          {border: props.hoverOutlineColor + " solid 1px"} : {}
        }
        onMouseEnter={()=>{setHover(true && props.disabled !== true)}} onMouseLeave={()=>{setHover(false)}}
      >
        <div className='d-flex justify-content-between'>
          <NumberFormat
            style={{
              width: "100%",
              backgroundColor: "#F3F5F7",
              cursor: props.disabled ? "not-allowed" : "default",
              fontSize: "1rem",
              padding: 0,
              fontFamily: "'DM Mono', monospace",
              fontWeight: 500
            }}
            thousandsGroupStyle="thousand"
            value={props.value}
            onValueChange={(values:any,sourceInfo:any) => { 
              if (sourceInfo.source !== 'prop' && props.valueChanged) {
                props.valueChanged(values.floatValue);
              }
            }}
            prefix={props.prefix}
            decimalScale={props.decimalScale}
            displayType="input"
            type="text"
            thousandSeparator={true}
            allowNegative={false}
            disabled={props.disabled}
          />
        </div>
       </div>
    </div>
  );
}