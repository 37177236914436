import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTable, usePagination, Row, Column } from 'react-table'
import './RankTable.scss'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Coin, RankTableChangeReason, SettingId } from '../shared/types';
import CoinCell from './CoinCell';
import { CoinForecasterService } from '../services/CoinForecasterService';
import { Subscription } from 'rxjs';
import { Grid } from  'react-loader-spinner'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { polyfill } from "seamless-scroll-polyfill";
polyfill();
var CurrencyFormat = require('react-currency-format');



const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 0;
    width: 100%;
    border-collapse: collapse;
    
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 0;
      border-right: 0;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`

interface Props {
  columns: Array<Column>;
  data: Array<any>;
  pageCount: number;
  pageIndex: React.MutableRefObject<number>;
  pageSize: React.MutableRefObject<number>;
  fetchData: (pageIndex: number, pageSize: number, eventType: RankTableChangeReason, selectedCoin: Coin) => void;
  loading: boolean;
  selectedCoin: Coin;
  tableChangeReason: React.MutableRefObject<RankTableChangeReason>;
};

function Table(props: Props) {
  const firstLoad = useRef(true);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: {
        pageSize: props.pageSize.current,
      },
      manualPagination: true,
      pageCount: props.pageCount
    } as any,
    usePagination
  ) as any
  const scrollRef = useRef<HTMLTableRowElement>(null);
  const executeScroll = () => {
    if (localStorage.getItem(SettingId.AutoScrollOnCoinSelect) == "true" && props.tableChangeReason.current == RankTableChangeReason.SelectedCoin) {
      scrollRef.current?.scrollIntoView({behavior: "smooth", block: "center"});
    }
    if (localStorage.getItem(SettingId.AutoForecast) == "true" && props.tableChangeReason.current == RankTableChangeReason.Typing) {
      scrollRef.current?.scrollIntoView({behavior: "smooth", block: "center"});
    }
    if (localStorage.getItem(SettingId.AutoForecast) == "false" && (props.tableChangeReason.current == RankTableChangeReason.ManualForecast || props.tableChangeReason.current == RankTableChangeReason.SelectedCoinReset)) {
      scrollRef.current?.scrollIntoView({behavior: "smooth", block: "center"});
    }
  }

  React.useEffect(() => {
    props.pageSize.current = pageSize;
    if (firstLoad.current) {
      props.tableChangeReason.current = RankTableChangeReason.IntialLoad
      firstLoad.current = false;
    }

    if (props.tableChangeReason.current == RankTableChangeReason.Pagination || props.tableChangeReason.current == RankTableChangeReason.IntialLoad)
      props.fetchData(pageIndex, pageSize,  props.tableChangeReason.current, props.selectedCoin);
  }, [pageIndex, pageSize])

  React.useEffect(() => {
    gotoPage(props.pageIndex.current);
  }, [props.pageIndex.current])

  React.useEffect(() => {
    if (!props.loading)
      executeScroll();
  }, [props.loading,props.selectedCoin])

  // Render the UI for your table
  return (
    <div className='rank-table-container'>
      <div className={props.loading ? 'd-flex justify-content-center align-items-center table-wrapper' : 'table-wrapper'}>
        {
          !props.loading &&
          <>
          <table {...getTableProps()}>
            <thead className='header'>
              {headerGroups.map((headerGroup: { getHeaderGroupProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableRowElement> & React.HTMLAttributes<HTMLTableRowElement>; headers: any[] }) => (
                <tr {...headerGroup.getHeaderGroupProps()} className='rank-table-row'>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}
                    style={{
                      fontWeight: "400",
                      paddingLeft: column.Header === "#" ? "20px" : "",
                      paddingRight: column.Header === "Circulating Supply" ? "20px" : ""
                    }}>{column.Header === "Market Cap" ? <span>{column.render('Header')}<ArrowDropDownIcon style={{marginBottom: "3px"}}/></span> : column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row: Row<object>, i: any) => {
                prepareRow(row)
                return (
                  <tr 
                    {...row.getRowProps()}
                    style={(row.original as any).id === props.selectedCoin.id ? {height: "60px", backgroundColor: "#3476E4", color: "white"} : {height: "60px"}}
                    ref={(row.original as any).id === props.selectedCoin.id ?  scrollRef: null} className='rank-table-row'>
                    {row.cells.map((cell: { getCellProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableDataCellElement> & React.TdHTMLAttributes<HTMLTableDataCellElement>; render: (arg0: string) => boolean | React.ReactFragment | React.ReactChild | React.ReactPortal | null | undefined }) => {
                      return <td 
                                {...cell.getCellProps()}
                                style={{
                                          border:  (row.original as any).id === props.selectedCoin.id ? 0 : "",
                                          backgroundColor: (row.original as any).id === props.selectedCoin.id ? "#3476E4" : "",
                                          paddingLeft: (cell as any).column.Header === "#" ? "20px" : "",
                                          paddingRight: (cell as any).column.Header === "Circulating Supply" ? "20px" : ""
                                        }
                                      }
                              >
                              {
                                (cell as any).column.Header === "Coin" ?  <CoinCell 
                                                                            selectedCoinId={props.selectedCoin.id}
                                                                            coinId={(row.original as any).id}
                                                                            coinName={(row.original as any).name}
                                                                            coinSymbol={(row.original as any).symbol}
                                                                            coinRank={(row.original as any).rank}
                                                                            coinLogo={(row.original as any).logo.toString()}
                                                                          /> :
                                (cell as any).column.Header === "Price" ? <CurrencyFormat value={CoinForecasterService.truncatePrice((row.original as any).price)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> :
                                (cell as any).column.Header === "Market Cap" ? <CurrencyFormat value={parseInt((cell as any).value.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'$'} /> :
                                (cell as any).column.Header === "Circulating Supply" ? <CurrencyFormat value={parseInt((cell as any).value.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={""} /> :
                                cell.render('Cell')
                              }
                            </td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className='paginator-wrapper d-flex justify-content-center'>
              {/* 
                Pagination can be built however you'd like. 
                This is just a very basic UI implementation:
              */}
              <div className="pagination">
                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '} */}
                <button 
                  className='paginator-btn'
                  style={{marginRight: "10px"}}
                  onClick={() => {
                    previousPage();
                    props.tableChangeReason.current = RankTableChangeReason.Pagination;
                  }} 
                  disabled={!canPreviousPage}
                >
                  <ArrowBackIcon/>
                </button>{' '}
                {/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button>{' '} */}
                <span>
                  Page{' '}
                    {pageIndex + 1} of {pageOptions.length}
                    {' '}
                </span>
                <button 
                  className='paginator-btn'
                  style={{marginLeft: "10px"}}
                  onClick={() => {
                    nextPage();
                    props.tableChangeReason.current = RankTableChangeReason.Pagination;
                  }}
                  disabled={!canNextPage}
                >
                  <ArrowForwardIcon/>
                </button>{' '}
                {/* <span>
                  | Go to page:{' '}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      gotoPage(page)
                    }}
                    style={{ width: '100px' }}
                  />
                </span> */}
                {/* {' '}
                <select
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select> */}
              </div>
          </div>
          </>
        }
        {
          props.loading &&
          <Grid
            color="#3476E4"
            // secondaryColor={"#3476E4"}
            height={100}
            width={100}
          />
        }
      </div>
    </div>
  )
}

function RankTable() {
  let selectedCoinSub: Subscription;
  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'rank'
      },
      {
        Header: 'Coin',
        accessor: 'name',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Market Cap',
        accessor: 'marketCap',
      },
      {
        Header: 'Circulating Supply',
        accessor: 'circulatingSupply',
      },
    ],
    []
  );
  const [pageCount, setPageCount] = useState(100);
  const pageSize = useRef(100);
  const pageIndex = useRef(0);
  const firstLoad = useRef(true);
  const [loading, setLoading] = useState(true);
  const tableData = useRef([] as Array<Coin>);
  const [selectedCoin, setSelectedCoin] = useState({} as Coin);
  const tableChangeReason = useRef(RankTableChangeReason.SelectedCoin);
  const fetchData = React.useCallback((pageIndexx: number, pageSize: number, eventType: RankTableChangeReason, selectedCoin: Coin) => {
    setLoading(true)
    CoinForecasterService.sendRankTableLoadingStatus({loading:true,loadingReason:eventType});
    CoinForecasterService.getRankTableData({
      selectedCoin: selectedCoin,
      pageSize: pageSize,
      pageIndex: pageIndexx,
      event: eventType
    }).then(response => {
      if (firstLoad.current) {
        if (response.pagesCoins.length > 0) {
          setSelectedCoin(response.pagesCoins[0]);
        }
        firstLoad.current = false;
      }

      selectedCoin.rank = response.coinProjectedRank;
      CoinForecasterService.sendForecastedCoin(selectedCoin);
      if (eventType == RankTableChangeReason.Typing || eventType == RankTableChangeReason.ManualForecast) { // track forecast
        CoinForecasterService.addForecast(selectedCoin);
      }
      CoinForecasterService.sendcoinRankTableCurrentPage({pageIndex:response.pageIndex,pagesCoins:response.pagesCoins});
      tableData.current = response.pagesCoins;
      setPageCount(response.pageCount);
      pageIndex.current = response.pageIndex;
      CoinForecasterService.sendRankTableLoadingStatus({loading:false,loadingReason:eventType});
      setLoading(false);
    })
  }, [])

    // mount
    useEffect(() => {
      selectedCoinSub = CoinForecasterService.selectedCoin$.subscribe(async (request) => {
          const coinOnPage = tableData.current.find(c => c.id == request.coin.id);
          const sameCoin = coinOnPage ? isSameCoin(coinOnPage,request.coin) : false;
          // this is to avoid unecessary loads. I know it looks like shit
          if (!coinOnPage ||((request.sendReason == RankTableChangeReason.ManualForecast || 
                              request.sendReason == RankTableChangeReason.Typing ||
                              request.sendReason == RankTableChangeReason.SelectedCoinReset) && !sameCoin)) {
                                fetchData(pageIndex.current, pageSize.current, request.sendReason, request.coin);
                              }
          else {
            // will make stats page show
            CoinForecasterService.sendForecastedCoin(request.coin);
            CoinForecasterService.sendRankTableLoadingStatus({loading:false,loadingReason:request.sendReason});
          }
          tableChangeReason.current = request.sendReason;
          setSelectedCoin(request.coin);
      });
    }, [] as any);
  
    // unmount
    useEffect(() => {
      return () => {
        if (selectedCoinSub) { selectedCoinSub.unsubscribe(); }
      }
    }, []);

    const isSameCoin = (coin1:Coin,coin2:Coin) => {
      return coin1.id == coin2.id &&
             coin1.price == coin2.price &&
             coin1.circulatingSupply == coin2.circulatingSupply &&
             coin1.marketCap == coin2.marketCap
    }

  return (
    <Styles>
      <Table
        columns={columns}
        data={tableData.current}
        fetchData={fetchData}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        loading={loading}
        selectedCoin={selectedCoin}
        tableChangeReason={tableChangeReason}
      />
    </Styles>
  )
}

export default RankTable
