import React, { useState } from 'react';
import './NavBar.scss';
// import logo from '../assets/laps-icon.png';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import DiscordIcon from '../assets/discord-24.png';
import { Nav, Navbar } from 'react-bootstrap';
import TwitterIcon from '@mui/icons-material/Twitter';
import SettingsIcon from '@mui/icons-material/Settings';
import logo from '../assets/logo.png';
import { Box, IconButton, Modal } from '@mui/material';
import SettingsSwitch from './SettingsSwitch';
import { SettingId } from '../shared/types';

interface Props {

};


const settingsModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  outline: 'none',
  borderRadius: "24px"
};


export default function NavBar(props: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const handleSettingsOpen = () => setOpen(true);
  const handleSettinhgsClose = () => setOpen(false);

  return (
    <>
      <Navbar className="navbar-container border-bottom" expand="sm" variant="light">
        <div className="custom-container">
          <a><img src={logo} className="logo" alt="logo"></img></a>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              
            </Nav>
            <IconButton className="navbar-item" onClick={() => { window.open("https://twitter.com/coinforecaster_", "_blank")}}>
                <TwitterIcon style={{color:'black', fontSize: "28px",padding:0}}/>
            </IconButton>
            <IconButton onClick={handleSettingsOpen} style={{cursor:"pointer"}}>
              <SettingsIcon style={{color:'black', fontSize: "28px",padding:0}}/>
            </IconButton>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Modal
        open={open}
        onClose={handleSettinhgsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={settingsModalStyle}>
          <div className='settings-modal'>
            <div className='modal-header'>
              Settings
            </div>
            <div className='modal-body'>
              {/* <SettingsSwitch
                settingId={SettingId.AutoForecast}
                settingTitle='Auto forecast'
                on={localStorage.getItem(SettingId.AutoForecast) == "true"}
              /> */}
              <SettingsSwitch
                settingId={SettingId.AutoScrollOnCoinSelect}
                settingTitle='Auto scroll on coin select'
                on={localStorage.getItem(SettingId.AutoScrollOnCoinSelect) == "true"}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
