export interface Coin {
    id: number;
    rank: number;
    name: string;
    logo?: string;
    symbol: string;
    price: number;
    marketCap: number;
    marketCapDominance: number;
    tags?: Array<string>;
    circulatingSupply: number;
}

export interface RankTableDataResponse {
    pagesCoins: Array<Coin>;
    coinProjectedRank: number;
    pageIndex: number;
    pageCount: number;
}

export interface RankTableDataRequest {
    selectedCoin: Coin,
    pageIndex?: number,
    pageSize: number,
    event: RankTableChangeReason
}

export enum RankTableChangeReason {
    ManualForecast = "manualForecast",
    Typing = "typing",
    SelectedCoin = "selectedCoin",
    Pagination = "pagination",
    IntialLoad = "intialLoad",
    SelectedCoinReset = "selectedCoinReset"
}

export interface RankTableCurrentPage {
    pageIndex: number;
    pagesCoins: Array<Coin>;
}

export interface RankTableLoadingStatus {
    loading: boolean;
    loadingReason: RankTableChangeReason;
}

export interface Setting {
    id: string;
    value: any;
}

export enum SettingId {
    AutoForecast = 'cf-auto-forecast',
    AutoScrollOnCoinSelect = 'cf-auto-scroll-coin-select'
}



