import React, { useEffect, useRef, useState } from 'react';
import TextField from './TextField';
import './MarketCapCalculator.scss';
import CoinSelector from './CoinSelector';
import { Subscription } from 'rxjs';
import { CoinForecasterService } from '../services/CoinForecasterService';
import { Coin, RankTableChangeReason, RankTableCurrentPage, Setting, SettingId } from '../shared/types';
import { isMobile } from 'react-device-detect';
import AdvancedCalcInput from './AdvancedCalcInput';
import { IoMdArrowDropup, IoMdClose } from 'react-icons/io';
import { CgMathEqual } from 'react-icons/cg';
import ForecastStat from './ForecastStat';
var CurrencyFormat = require('react-currency-format');
import moment, { Moment } from 'moment';




interface Props {

};



export default function MarketCapCalculator(props: Props): JSX.Element {
    let starterCoinsSub = useRef(new Subscription());
    let settingSub = useRef(new Subscription());
    let rankTableLoadingStatusSub = useRef(new Subscription());
    let forecastedCoinSub = useRef(new Subscription());
    const [selectedCoin, setSelectedCoin] = useState({} as Coin || undefined);
    const firstLoad = useRef(true);
    const [loading, setLoading] = useState(true);
    const backupSelectedCoin = useRef({} as Coin);
    const [price, setPrice] = useState(0 as any);
    const [circulatingSupply, setCirculatingSupply] = useState(0);
    const [marketCap, setMarketCap] = useState(0);
    const [coinSelections, setCoinSelections] = useState([] as Array<Coin>);
    const selectedCoinInputsChangeReason = useRef('selection');
    const [autoForecastEnabled, setAutoForecast] = useState(localStorage.getItem(SettingId.AutoForecast) == "true")
    const [currentView, setCurrentView] = useState("calc");

    // mount
    useEffect(() => {
        starterCoinsSub.current = CoinForecasterService.rankTableCurrentPage$.subscribe((RankTableCurrentPage:RankTableCurrentPage) => {
            if (RankTableCurrentPage.pageIndex === 0) { // update coinSelector coins' data to be in sync with table
                setCoinSelections(RankTableCurrentPage.pagesCoins);
            }
            syncSelectedCoinWithRankTable(RankTableCurrentPage.pagesCoins);
            if (firstLoad.current && RankTableCurrentPage.pagesCoins && RankTableCurrentPage.pagesCoins.length > 0) {
                firstLoad.current = false;
                onCoinSelected(RankTableCurrentPage.pagesCoins[0], true);
            }
        });
        settingSub.current = CoinForecasterService.setting$.subscribe((setting:Setting) => {
            if (setting.id == SettingId.AutoForecast) {
                setAutoForecast(setting.value);
            }
        })
        forecastedCoinSub.current = CoinForecasterService.forecastedCoin$.subscribe((forecastedCoin:Coin) => {
            setSelectedCoin(forecastedCoin);
        })
        rankTableLoadingStatusSub.current = CoinForecasterService.rankTableLoadingStatus$.subscribe((status) => {
            if (status.loadingReason == RankTableChangeReason.ManualForecast){
                setLoading(status.loading);
                if (!status.loading)
                    setCurrentView('stats');
            }
        })
    }, []);

    useEffect(() => {
        return () => {
          if (starterCoinsSub.current) { starterCoinsSub.current.unsubscribe(); }
          if (settingSub.current) { settingSub.current.unsubscribe(); }
          if (forecastedCoinSub.current) { forecastedCoinSub.current.unsubscribe(); }
          if (rankTableLoadingStatusSub.current) { rankTableLoadingStatusSub.current.unsubscribe(); }
        }
      }, [starterCoinsSub]);
  
  const syncSelectedCoinWithRankTable = (currentPagesCoins: Array<Coin>) => {
    const coinOnPage =  currentPagesCoins.find(x => x.id === selectedCoin.id);
    if (coinOnPage) {
        backupSelectedCoin.current = coinOnPage;
        if (inputsHaventChanged() === true) { // hasn't been changed so update it
            selectedCoinInputsChangeReason.current = 'syncWithRankTable';
            setPrice(coinOnPage.price);
            setCirculatingSupply(coinOnPage.circulatingSupply);
            setMarketCap(coinOnPage.marketCap);
        }
    }
  }

  const onCoinSelected = (coin: Coin, starterCoins:boolean = false) => {
    selectedCoinInputsChangeReason.current = 'selection';
    setSelectedCoin({...coin});
    backupSelectedCoin.current = {...coin};
    setPrice(CoinForecasterService.truncatePrice(coin.price));
    setCirculatingSupply(coin.circulatingSupply);
    setMarketCap(coin.marketCap);
    if (!starterCoins)
        sendSelectedCoin(coin.price,coin.marketCap,coin.circulatingSupply,coin, RankTableChangeReason.SelectedCoin);
  }

  const getStatMultiplier = (originalStat:number,currentStat:number) => {
    const increase = currentStat/originalStat;
    return (Math.round(increase * 100) / 100);
  }

  const priceChanged = (price: number) => {
    selectedCoinInputsChangeReason.current = 'typing';
    setPrice(price);
    if (!price) {
        setMarketCap('' as any);
    }
    if (price && price !== 0 && circulatingSupply && circulatingSupply) {
        setMarketCap(circulatingSupply*price);
    }
  }

  const circulatingSupplyChanged = (circulatingSupply: number) => {
    selectedCoinInputsChangeReason.current = 'typing';
    setCirculatingSupply(circulatingSupply);
    if (selectedCoinIsStableCoin()) { 
        if (circulatingSupply && circulatingSupply !== 0 && price && price !== 0) {
            setMarketCap(circulatingSupply*price);
        }
    } else {
        if (!circulatingSupply) {
            setMarketCap('' as any);
        }
        if (circulatingSupply && circulatingSupply !== 0 && price && price !== 0) {
            setMarketCap(circulatingSupply*price);
        }
    }
  }

  const marketCapChanged = (marketCap: number) => {
    selectedCoinInputsChangeReason.current = 'typing';
    setMarketCap(marketCap);
    if (selectedCoinIsStableCoin()) {
        if (marketCap && marketCap !== 0 && price && price !== 0) {
            setCirculatingSupply(marketCap/price);
        }
    } else {
        if (!marketCap) {
            setPrice('' as any);
        }
        if (marketCap && marketCap !== 0 && circulatingSupply && circulatingSupply !== 0) {
            setPrice(marketCap/circulatingSupply);
        }
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
        if (price && marketCap && circulatingSupply && selectedCoinInputsChangeReason.current === 'typing' && autoForecastEnabled) {
                sendSelectedCoin(price,marketCap,circulatingSupply,selectedCoin, RankTableChangeReason.Typing);
            }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [price,marketCap,circulatingSupply])

  const sendSelectedCoin = (price:number,marketCap:number,circulatingSupply:number,coin:Coin, sendReason: RankTableChangeReason) => {
    const cc = {...coin};
    cc.price = price == CoinForecasterService.truncatePrice(coin.price) ? coin.price : price; // so equality doesn't get messed up later
    cc.circulatingSupply = circulatingSupply;
    cc.marketCap = marketCap;
    CoinForecasterService.sendSelectedCoinToRankTable({coin: cc, sendReason: sendReason});
  }

  const reset = () => {
    setPrice(CoinForecasterService.truncatePrice(backupSelectedCoin.current.price));
    setCirculatingSupply(backupSelectedCoin.current.circulatingSupply);
    setMarketCap(backupSelectedCoin.current.marketCap);
    selectedCoinInputsChangeReason.current = 'reset';
    sendSelectedCoin(backupSelectedCoin.current.price,
                     backupSelectedCoin.current.marketCap,
                     backupSelectedCoin.current.circulatingSupply,
                     selectedCoin,
                     RankTableChangeReason.SelectedCoinReset);
  }

  const inputsHaventChanged = () => {
    if (Object.keys(backupSelectedCoin.current).length === 0) {
        return true;
    }
    const truncatedPrice = CoinForecasterService.truncatePrice(backupSelectedCoin.current.price);
    return (typeof price == 'string' ? price === truncatedPrice : price?.toString() === truncatedPrice) &&
           circulatingSupply === backupSelectedCoin.current.circulatingSupply &&
           marketCap === backupSelectedCoin.current.marketCap
  }

  const disableForecastButton = () => {
      return !price || !circulatingSupply || !marketCap;
  }

  const selectedCoinIsStableCoin = () => {
      return selectedCoin.tags?.includes('stablecoin');
  }

  const closeStatsView = () => {
      setCurrentView('calc');
  }

  const getPercentageIncrease = (startingValue:number, finalValue:number) => {
    return (finalValue - startingValue/Math.abs(startingValue))*100;
  }

  // tooltip can't get clicked on mobile for some reason
  const circulatinSupplyInfoText = isMobile ? `<p style="margin:0">The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.</p>` :
                                              `<p>The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.</p>
                                              <a class="text-field-tooltip-link" target='blank' rel='noopener noreferrer' href='https://support.coinmarketcap.com/hc/en-us/articles/360043396252-Supply-Circulating-Total-Max-' style="color:#3476E4;text-decoration:none;font-weight:bold;">Read More<a>`;
  const marketCapInfoText = isMobile ? `<p>The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market.</p><p style="margin:0">Market Cap = Current Price x Circulating Supply.</p>` :
                                        `<p>The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market.</p><p>Market Cap = Current Price x Circulating Supply.</p>
                                        <a class="text-field-tooltip-link" target='blank' rel='noopener noreferrer' href='https://support.coinmarketcap.com/hc/en-us/articles/360043836811-Market-Capitalization-Cryptoasset-Aggregate-' style="color:#3476E4;text-decoration:none;font-weight:bold;">Read More<a>`

  return (
    <div className={'mc-container border'}>
                {
            currentView == "calc" &&
            <div className='calc-view'>
            <div className='calc-header border-bottom d-flex justify-content-center flex-column'>
                <div className='calulate-heading'>
                    Forecast
                </div>
                <div className='instructions'>
                    Adjust the inputs to make instant coin projections
                </div>
            </div>
            <div className='body'>
            <div className='coin-select-wrapper'>
                <CoinSelector selectedCoin={selectedCoin} onCoinSelected={onCoinSelected} coinSelections={coinSelections}/>
            </div>
            <div className='inputs'>
                <AdvancedCalcInput
                    name='Price'
                    hoverOutlineColor='#CED0D9'
                    marginBottom='0.4rem'
                    prefix="$"
                    value={price}
                    valueChanged={priceChanged}
                    disabled={selectedCoinIsStableCoin() || firstLoad.current}
                />
                {/* <div className='symbol-wrapper'>
                    <div className='symbol border d-flex justify-content-center align-items-center'>
                        x
                    </div>
                </div> */}
                <AdvancedCalcInput
                    name='Circulating Supply'
                    hoverOutlineColor='#CED0D9'
                    marginBottom='0.4rem'
                    value={circulatingSupply}
                    decimalScale={0}
                    valueChanged={circulatingSupplyChanged}
                    disabled={firstLoad.current}
                    infoText={circulatinSupplyInfoText}
                />
                 {/* <div className='symbol-wrapper  d-flex justify-content-center'>
                    <div className='symbol border d-flex justify-content-center align-items-center'>
                        <CgMathEqual/>
                    </div>
                </div> */}
                <AdvancedCalcInput
                    name='Market Cap'
                    hoverOutlineColor='#CED0D9'
                    marginBottom='12px'
                    prefix="$"
                    value={marketCap}
                    decimalScale={0}
                    valueChanged={marketCapChanged}
                    disabled={firstLoad.current}
                    infoText={marketCapInfoText}
                />
            </div>
            <div>
                {
                    !autoForecastEnabled &&
                    <button
                        style={{marginBottom: "0.4rem"}}
                        className='calc-btn'
                        onClick={()=>{sendSelectedCoin(price,marketCap,circulatingSupply,selectedCoin, RankTableChangeReason.ManualForecast)}}
                        disabled={disableForecastButton() ==  true}
                    >
                            Forecast
                    </button>
                }
                <button 
                    className='calc-btn'
                    onClick={()=>{reset()}} 
                    disabled={inputsHaventChanged() === true}>
                        Reset
                </button>
            </div>
        </div>  
            </div>   
        }  
        {
            currentView == 'stats' &&
            <div className='stats-view'>
            <div className='header d-flex justify-content-center flex-column'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='heading d-flex'>
                        <img className="coin-logo" alt="selected coin" src={selectedCoin.logo}/>
                        <span style={{lineHeight:"25px"}}>{selectedCoin.symbol} Forecast</span>
                    </div>
                    <IoMdClose style={{fontSize: "24px", cursor: "pointer"}} onClick={()=>{closeStatsView()}}/>
                </div>
                {/* <div className='sub-heading'>
                    If BTC had a price of <CurrencyFormat value={CoinForecasterService.truncatePrice(price)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                </div> */}
            </div>
            <div className='stats'>
                <ForecastStat
                      statLabel={"Market Rank"}
                      statIncreaseSymbol={""}
                      actualStat={"#"+selectedCoin.rank}
                      marginBottom={"10px"}
                />
                <ForecastStat
                      statLabel={"Price"}
                      statIncreaseSymbol={"%"}
                      actualStat={<CurrencyFormat
                                    renderText={(value:any)=><div style={{whiteSpace: "nowrap",overflow:"hidden",textOverflow:"ellipsis", width:"268px"}}>{value}</div>}
                                    value={CoinForecasterService.truncatePrice(price)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                  />
                                }
                      marginBottom={"10px"}
                />
                <ForecastStat
                      statLabel={"Circulating Supply"}
                      statIncreaseSymbol={"x"}
                      actualStat={<CurrencyFormat 
                                    renderText={(value:any)=><div style={{whiteSpace: "nowrap",overflow:"hidden",textOverflow:"ellipsis", width:"268px"}}>{value}</div>}
                                    value={parseInt(circulatingSupply.toFixed(0))}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={""} 
                                   />
                                }
                      marginBottom={"10px"}
                />
                <ForecastStat
                      statLabel={"Market Cap"}
                      statIncreaseSymbol={"x"}
                      actualStat={<CurrencyFormat
                                    renderText={(value:any)=><div style={{whiteSpace: "nowrap",overflow:"hidden",textOverflow:"ellipsis", width:"268px"}}>{value}</div>} 
                                    value={parseInt(marketCap.toFixed(0))}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                  />
                                }
                      marginBottom={"10px"}
                />
                <ForecastStat
                      statLabel={"Multiplier"}
                      statIncreaseSymbol={""}
                      actualStat={<CurrencyFormat
                                    renderText={(value:any)=><div style={{whiteSpace: "nowrap",overflow:"hidden",textOverflow:"ellipsis", width:"268px"}}>{value}</div>}
                                    value={getStatMultiplier(backupSelectedCoin.current.marketCap,marketCap)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    suffix={"x"} 
                                   />
                                 }
                      marginBottom={""}
                />
                {/* <ForecastStat
                      statLabel={"Market Dominance"}
                      statIncreaseSymbol={"%"}
                      actualStat={"42.09%"}
                      marginBottom={""}
                /> */}
            </div>
            <div className='end-section'>
                <div>Forecasted on {moment().format("LL")}</div>
                <div>coinforecaster.xyz</div>
            </div>
        </div>
        } 
</div>
  );
}
