import React from 'react';
import './CoinCell.scss';


type Props  = {
    coinId: number;
    coinName: string;
    coinSymbol: string;
    coinRank: number;
    coinLogo: string;
    selectedCoinId: number;
};

export default function CoinCell(props: Props): JSX.Element {

  return (
    <div className='coin-cell-container d-flex align-items-center'>
      <img 
        className="coin-logo"
        alt={props.coinName + " logo"}
        src={props.coinLogo}
      />
      <div className='d-flex flex-column'>
        <span>{props.coinName}</span>
        <span style={{color: props.coinId === props.selectedCoinId ? "white": "grey"}}>{props.coinSymbol}</span>
      </div>                                
    </div>
  );
}