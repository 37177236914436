/* eslint-disable no-throw-literal */
import axios from 'axios';
import { BehaviorSubject, Subject } from 'rxjs';
import { 
    Coin,
    RankTableChangeReason,
    RankTableCurrentPage,
    RankTableDataRequest,
    RankTableDataResponse,
    RankTableLoadingStatus,
    Setting 
} from '../shared/types';
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from '../firebase-config';

export class CoinForecasterService {
    // Sharing data between components
    private static setting = new Subject<Setting>();
    static get setting$(): Subject<Setting> {
        return this.setting;
      }
    public static sendSetting(setting:Setting) {
        this.setting.next(setting);
    }

    private static selectedCoin = new Subject<{coin: Coin, sendReason: RankTableChangeReason}>();
    static get selectedCoin$(): Subject<{coin: Coin, sendReason: RankTableChangeReason}> {
        return this.selectedCoin;
      }
    public static sendSelectedCoinToRankTable(request:{coin: Coin, sendReason: RankTableChangeReason}) {
        this.selectedCoin.next(request);
    }

    private static RankTableCurrentPage = new BehaviorSubject<RankTableCurrentPage>({pageIndex: 0, pagesCoins: []});
    static get rankTableCurrentPage$(): Subject<RankTableCurrentPage> {
        return this.RankTableCurrentPage;
      }
    public static sendcoinRankTableCurrentPage(request:RankTableCurrentPage) {
        this.RankTableCurrentPage.next(request);
    }

    private static rankTableLoadingStatus = new Subject<RankTableLoadingStatus>();
    static get rankTableLoadingStatus$(): Subject<RankTableLoadingStatus> {
        return this.rankTableLoadingStatus;
      }
    public static sendRankTableLoadingStatus(data:RankTableLoadingStatus) {
        this.rankTableLoadingStatus.next(data);
    }

    // used for additional stats for a coin that come from API
    private static forecastedCoin = new Subject<Coin>();
    static get forecastedCoin$(): Subject<Coin> {
        return this.forecastedCoin;
      }
    public static sendForecastedCoin(data:Coin) {
        this.forecastedCoin.next(data);
    }

    // Firebase
    static async addForecast(coin:Coin) {
        if(process.env.REACT_APP_ENV != 'production') return;
        const forecastsCollectionRef = collection(db, "forecast");
        await addDoc(forecastsCollectionRef, {
            coinId: coin.id,
            projectedRank: coin.rank,
            coinName: coin.name,
            coinSymbol: coin.symbol,
            price: coin.price,
            circulatingSupply: coin.circulatingSupply,
            marketCap: coin.marketCap,
            createdAt: Timestamp.fromDate(new Date())
        })

    }

    // API Calls
    static async getRankTableData(request:RankTableDataRequest) {
        try {
            const response = await axios.post<RankTableDataResponse>(`${process.env.REACT_APP_API_BASE_URL}api/coinforecaster/getRankTableData`, request, {
                headers: {

                },
            })

            return response.data;
        } catch(error) {
            throw `getRankTableData API Call failed ${error}`;
        }
    }

    static async searchForCoin(query:string|null) {
        const request = {query: query};
        try {
            const response = await axios.post<Array<Coin>>(`${process.env.REACT_APP_API_BASE_URL}api/coinforecaster/searchforcoin`, request, {
                headers: {

                },
            })

            return response.data;
        } catch(error) {
            throw `getRankTableData API Call failed ${error}`;
        }
    }

    /* Helper Methods */
    public static truncatePrice(price:number): any {
        if (price >= 1) {
            const rounded = (Math.round(price * 100) / 100).toString();
            if (!rounded.includes('.')) { return rounded + '.00'} // no decimal places
            if (rounded.substring(rounded.indexOf('.')+1).length === 2) { return rounded; } // two decimals
            return rounded + '0'; // 1 decimal
        }
        const priceString = price.toString().includes('e') ? CoinForecasterService.convertRaisedToPower10ToNormalFormat(price.toString()) : price.toString();
        let dotIndex = priceString.indexOf('.') + 1;

        for (let i = dotIndex+1; i < priceString.length; i++) {
            if (priceString.charAt(i) !== '0') {
                return priceString.substring(0,i+3);
            }
        }

        return priceString;
    }

    private static convertRaisedToPower10ToNormalFormat(priceString:string) {
        let power = parseInt(priceString.substring(priceString.indexOf('-')+1));
        priceString =  priceString.substring(0,priceString.indexOf('e'));
        priceString = priceString.replace(".", "");

        while (power > 1) {
            priceString = "0" + priceString;
            power--;
        }

        priceString = "0." + priceString;

        return priceString;
    }
}