import React, { useEffect } from 'react';
import './ForecastStat.scss';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';



interface Props {
  statLabel: string;
  statIncrease?: number;
  statIncreaseSymbol?: string;
  actualStat: any;
  marginBottom: string;
};


export default function ForecastStat(props: Props): JSX.Element {

    // mount
    useEffect(() => {

    }, []);
  
    // unmount
    useEffect(() => {
      return () => {
        
      }
    }, []);

  return (
   <div className='forecast-stat-container' style={{marginBottom: props.marginBottom}}>
      <div className='stat-label'>
        {props.statLabel}
        { 
          props.statIncrease && props.statIncrease > 0 &&
          <span className='stat-data-positive'><IoMdArrowDropup/>{props.statIncrease}{props.statIncreaseSymbol}</span>
        }
        { 
          props.statIncrease && props.statIncrease < 0 &&
          <span className='stat-data-negative'><IoMdArrowDropdown/>{props.statIncrease}{props.statIncreaseSymbol }</span>
        }
      </div>
      <div>
          <div>
              <span className='actual-stat'>{props.actualStat}</span>
          </div>  
      </div>
  </div>
  );
}