import React, { useEffect, useRef, useState } from 'react';
import './CoinSelector.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from './TextField';
import CoinSelectorItem from './CoinSelectorItem';
import { Coin, RankTableChangeReason } from '../shared/types';
import { CoinForecasterService } from '../services/CoinForecasterService';
import { Subscription } from 'rxjs';
import { Grid } from 'react-loader-spinner';
import { MdArrowDropUp } from 'react-icons/md';


interface Props {
  onCoinSelected: (coin:Coin) => void;
  coinSelections: Array<Coin>
  selectedCoin: Coin
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: "557px",
  backgroundColor: 'white',
  outline: 'none',
  borderRadius: "24px"
};

export default function CoinSelector(props: Props): JSX.Element {
  const [open, setOpen] = React.useState(false);
  let rankTableLoadingStatusSub = useRef(new Subscription());
  let defaultCoinSelections = useRef([] as Array<Coin>);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedCoin, setSelectedCoin] = useState({} as Coin);
  const [coinSelections, setCoinSelections] = useState([] as Array<Coin>);
  const [query,setQuery] = useState(null as (string|null));
  const firstLoad = useRef(true);
  const [searchLoading,setSearchLoading] = useState(false);
  const [rankTableLoading,setRankTableLoading] = useState(false);

    // mount
    useEffect(() => {
    }, []);

    useEffect(() => {
      setCoinSelections(props.coinSelections);
      defaultCoinSelections.current = props.coinSelections;
      if (firstLoad.current && props.coinSelections.length > 0) {
        firstLoad.current = false;
        setSelectedCoin(props.selectedCoin.id ? props.selectedCoin : props.coinSelections[0]);
      }
      rankTableLoadingStatusSub.current = CoinForecasterService.rankTableLoadingStatus$.subscribe((status) => {
        if (status.loadingReason != RankTableChangeReason.Pagination && status.loadingReason != RankTableChangeReason.SelectedCoin){
          setRankTableLoading(status.loading);
        }
    })
  }, [props.coinSelections]);
  
    // unmount
    useEffect(() => {
      return () => {
        
      }
    }, []);
  
  const onCoinSelected = (coin: Coin) => {
    setSelectedCoin(coin);
    setOpen(false);
    // reset coin selections
    setCoinSelections(defaultCoinSelections.current);
    props.onCoinSelected(coin);
  }

  const coinNameChanged = (query:string) => {
    setQuery(query);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
          if (query != null) {
            setSearchLoading(true);
            setCoinSelections(await CoinForecasterService.searchForCoin(query));
            setSearchLoading(false);
          }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [query])

  return (
    <>
      <div className='coin-selector-container d-flex justify-content-center'>
          <div className='inner-container' onClick={handleOpen}>
            {
              !rankTableLoading &&
              <div className='coin-selector-button' style={{borderRadius: "16px"}}>
                <img className="coin-logo" alt="selected coin" src={selectedCoin.logo}/>
                <span>{selectedCoin.symbol}</span>&nbsp;
                {/* <span className='multiplier-increase'>(100x)</span> */}
                <KeyboardArrowDownIcon style={{fontSize: "20px", marginBottom: "2px"}}/>
              </div>
            }
            {
              rankTableLoading &&
                <Grid
                color="#3476E4"
                // secondaryColor={"#3476E4"}
                height={20}
                width={20}
              />
            }
          </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className='coin-selector-modal'>
            <div className='modal-header'>
              Select Coin
            </div>
            <div className='modal-body'>
              <div className='text-field-wrapper'>
                <TextField selectedOutlineColor='#CED0D9' placeholderText='Search coin name' valueChanged={coinNameChanged}/>
              </div>
              <div className={searchLoading || (!searchLoading && coinSelections.length === 0) ? 'd-flex justify-content-center coin-list' : 'coin-list'}>
                {
                  !searchLoading && coinSelections.length > 0 &&
                  coinSelections.map(coin => <CoinSelectorItem
                    key={coin.id}
                    selectedCoin={selectedCoin} 
                    coin={coin}
                    onCoinSelected={onCoinSelected}
                    />)
                }
                {
                  !searchLoading && coinSelections.length === 0 &&
                  <div className='no-results-found'>No results found.</div>
                }
                {
                    searchLoading &&
                    <Grid
                      color="#3476E4"
                      // secondaryColor={"#3476E4"}
                      height={20}
                      width={20}
                    />
                }
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}