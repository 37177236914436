import React, { useEffect } from 'react';
import './Home.scss';
import MarketCapCalculator from '../components/MarketCapCalculator';
import RankTable from '../components/RankTable';
import useWindowDimensions from '../shared/hooks/useWindowDimensions';


interface Props {

};

export default function Home(props: Props): JSX.Element {
  const { width } = useWindowDimensions();

    // mount
  useEffect(() => {

  }, []);

  // unmount
  useEffect(() => {
    return () => {
     
    }
  }, []);
  
  return (
    <div className={width >= 990 ? "home-container d-flex justify-content-between" : "home-container d-flex flex-column align-items-center"}>
        <div className='calculator-wrapper'><MarketCapCalculator/></div>
        <div className='rank-table-wrapper'><RankTable/></div>
    </div>
  );
}
