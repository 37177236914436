import './App.scss';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { isMobile, isDesktop } from 'react-device-detect';
import { SettingId } from './shared/types';

function App() {
  if (isDesktop && !localStorage.getItem(SettingId.AutoForecast)) { 
    localStorage.setItem(SettingId.AutoForecast, "false");
  }
  if (isDesktop && !localStorage.getItem(SettingId.AutoScrollOnCoinSelect)) { 
    localStorage.setItem(SettingId.AutoScrollOnCoinSelect, "true");
  }
  if (isMobile && !localStorage.getItem(SettingId.AutoForecast)) { 
    localStorage.setItem(SettingId.AutoForecast, "false");
  }
  if (isMobile && !localStorage.getItem(SettingId.AutoScrollOnCoinSelect)) { 
    localStorage.setItem(SettingId.AutoScrollOnCoinSelect, "false");
  }


  
  return (
    <div>
      <NavBar/>
      <Router>
        <div className="app-container custom-container">
          <Switch>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      </div>
  );
}

export default App;
